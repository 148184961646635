// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import Firestore
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyADPUlwwYAXFNTctG0jC6pk_3JfUYTr5wY",
  authDomain: "portfolio-site-d8189.firebaseapp.com",
  projectId: "portfolio-site-d8189",
  storageBucket: "portfolio-site-d8189.firebasestorage.app",
  messagingSenderId: "506314353177",
  appId: "1:506314353177:web:2a89acd0d5cb3057635888",
  measurementId: "G-XFJFHH5LQJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app,"portfolio-data");

export { app, db };