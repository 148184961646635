import React from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";

const AddTestData = () => {
  const addTestDataToFirestore = async () => {
    try {
      // Add a new document with a generated id.
      
      const features = 

        [
            {
              title: "Gladiator 2",
              studio: "Paramount Studios",
              credit: "Senior VFX Editor",
              description: "Served as the on-location Senior VFX Editor across Morocco, Malta, France, and the UK, coordinating complex VFX turnovers to ensure high-quality standards.",
              awards: "TBD - Film set for release in 2024.",
              imageUrl: "gs://portfolio-site-d8189.firebasestorage.app/Gladiator2_Lucius.jpg",
              order:100,
              display:true
            },
            {
              title: "Mission Impossible Dead Reckoning",
              studio: "Paramount Studios",
              credit: "Additional VFX Editor",
              description: "Managed the integration of VFX submissions during the final production phases, ensuring seamless alignment with editorial changes.",
              awards: "None listed.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/MissionImpossible_DeadReckoning.jpg",
              order:80,
              display:true
            },
            {
              title: "Napoleon",
              studio: "Apple Studios",
              credit: "VFX Editor",
              description: "Oversaw the complete VFX turnover process, coordinating with post-viz and multiple vendors while actively tracking and implementing editorial changes.",
              awards: "None listed.",
              imageUrl: "gs://portfolio-site-d8189.firebasestorage.app/Napoleon.jpg",
              order:90,
              display:true
            },
            {
              title: "Venom: Let There Be Carnage",
              studio: "Sony",
              credit: "VFX Editor",
              description: "Led the VFX turnover process, coordinating with post-viz and vendors to ensure accurate integration of VFX across complex scenes.",
              awards: "None listed.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/Venom2.jpg",
              order:70,
              display:true
            },
            {
              title: "Maleficent: Mistress of Evil",
              studio: "Disney",
              credit: "VFX Editor",
              description: "Designed and managed the VFX turnover process, handling extensive coordination for VFX integration and Anyma facial capture data.",
              awards: "Nominated for 1 Oscar: Best Makeup and Hairstyling.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/Maleficent2.jpg",
              order:65,
              display:true
            },
            {
              title: "Mission Impossible: Fallout",
              studio: "Paramount Pictures",
              credit: "Stereo Editor (Uncredited)",
              description: "Maintained the stereo cut during the final conversion stages, working closely with the stereo supervisor to ensure depth accuracy.",
              awards: "None listed.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/MissionImpossible_fallout.jpg",
              order:62,
              display:true
            },
            {
              title: "Serenity",
              studio: "IM Global",
              credit: "VFX Editor",
              description: "Developed a comprehensive VFX production database, streamlining asset tracking and project management.",
              awards: "None listed.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/Serenity.jpg",
              order:55,
              display:"false"
            },
            {
              title: "The Current War",
              studio: "TWC",
              credit: "VFX Editor",
              description: "Managed shot tracking and turnover, ensuring timely delivery of VFX submissions.",
              awards: "None listed.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/TheCurrentWar.jpg",
              order:50,
              display:true
            },
            {
              title: "Fall",
              studio: "Lionsgate",
              credit: "Associate Editor",
              description: "Supervised and coordinated complex visual effects sequences, ensuring seamless integration with live-action shots in this gripping survival thriller.",
              awards: "None listed.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/Fall.jpg",
              order:95,
              display:true
            },
            {
              title: "Alien Covenant",
              studio: "20th Century Fox",
              credit: "VFX Editorial Support",
              description: "Designed and maintained a custom VFX production FileMaker database, supporting efficient shot tracking and data management.",
              awards: "None listed.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/AlienCovenant.jpg",
              order:63,
              display:true
            },
            {
              title: "Star Wars: Rogue One",
              studio: "Lucasfilm Ltd",
              credit: "VFX Editor",
              description: "Led the turnover and tracking of VFX across multiple cuts, ensuring consistency in complex visual effects sequences.",
              awards: "Nominated for 2 Oscars: Best Visual Effects, Best Sound Mixing.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/RogueOne.jpg",
              order:80,
              display:true
            },
            {
              title: "The Danish Girl",
              studio: "Working Title Films",
              credit: "VFX Editor",
              description: "Developed and maintained a comprehensive element tracking database, streamlining the submission process and ensuring accurate tracking of all VFX shots.",
              awards: "Won 1 Oscar: Best Actress (Alicia Vikander). Nominated for 3 others.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/TheDanishGirl.jpg",
              order:45,
              display:true
            },
            {
              title: "Victor Frankenstein",
              studio: "20th Century Fox",
              credit: "VFX Editor",
              description: "Managed the VFX shot and element tracking database, ensuring all submissions and vendor bids were accurately logged and tracked to support production timelines.",
              awards: "None listed.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/VictorFrankenstein.jpg",
              order:30,
              display:true
            },
            {
              title: "World War Z",
              studio: "Paramount Pictures",
              credit: "VFX Assistant Editor",
              description: "Produced temporary VFX and handled shot turnovers, ensuring seamless integration of VFX shots into the editorial timeline.",
              awards: "Nominated for multiple technical awards.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/WWZ.jpg",
              order:60,
              display:true
            },
            {
              title: "X-Men: First Class",
              studio: "20th Century Fox",
              credit: "VFX Data Coordinator",
              description: "Coordinated 2K director reviews and managed director/studio CineSync sessions, facilitating efficient communication and feedback on VFX sequences.",
              awards: "None listed.",
              imageUrl:"gs://portfolio-site-d8189.firebasestorage.app/XMenFC.jpg",
              order:20,
              display:true
            }
          ];
          

      for (const feature of features) {
        const docRef = await addDoc(collection(db, "features"), feature);
        console.log(`Document written with ID: ${docRef.id}`);
      }

      console.log("Test data added successfully.");
    } catch (error) {
      console.error("Error adding test data: ", error);
    }
  };

  return (
    <div>
      <h1>Add Test Data to Firestore</h1>
      <button onClick={addTestDataToFirestore}>Add Test Data</button>
    </div>
  );
};

export default AddTestData;
