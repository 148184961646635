import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  collection,
  query,
  getDocs,
  orderBy,
  where,
} from "firebase/firestore";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LocomotiveScroll from "locomotive-scroll";
import { db } from "./firebaseConfig";
import "locomotive-scroll/dist/locomotive-scroll.css";
import "./App.css";
import AddTestData from "./AddTestData";
import AddTestDataShorts from "./AddTestDataShorts";
import backgroundImage from "./assets/images/FilmCutterLtd_MC.png";
import favicon from "./assets/images/favicon.png";

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const locoScrollRef = useRef(null);
  const scrollRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [features, setFeatures] = useState([]);
  const [shortFilms, setShortFilms] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoReady, setVideoReady] = useState(true);

  useEffect(() => {
    const initializeWebsite = async () => {
      /*console.log("Initializing website...");*/
      try {
        const storage = getStorage();
        const videoRef = ref(storage, "portfolio_video_v3_draft.mp4");
        const url = await getDownloadURL(videoRef);
        setVideoUrl(url);

        // Fetch Features
        const featuresCollection = collection(db, "features");
        const featuresQuery = query(
          featuresCollection,
          where("display", "==", true),
          orderBy("order", "desc")
        );
        const featuresSnapshot = await getDocs(featuresQuery);
        const featuresWithUrls = await Promise.all(
          featuresSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const imageUrl = await getDownloadURL(ref(storage, data.imageUrl));
            return { id: doc.id, ...data, imageUrl };
          })
        );
        setFeatures(featuresWithUrls);

        // Fetch Shorts
        const shortsCollection = collection(db, "shorts");
        const shortsQuery = query(
          shortsCollection,
          where("display", "==", true),
          orderBy("order", "desc")
        );
        const shortFilmsSnapshot = await getDocs(shortsQuery);
        const shortsWithUrls = await Promise.all(
          shortFilmsSnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const imageUrl = await getDownloadURL(ref(storage, data.imageUrl));
            return { id: doc.id, ...data, imageUrl };
          })
        );
        setShortFilms(shortsWithUrls);

        /* console.log("Data Fetched."); */
        setIsLoading(false); // ✅ Ensures that Locomotive Scroll starts only after data is ready.
      } catch (error) {
        /* console.error("Error Fetching Data:", error); */
        setIsLoading(false);
      }
    };

    initializeWebsite();
  }, []);

  useEffect(() => {
    const checkScrollRef = setInterval(() => {
      if (!isLoading && scrollRef.current && !locoScrollRef.current) {
        /* console.log("scrollRef found. Initializing Locomotive Scroll..."); */
        clearInterval(checkScrollRef);

        locoScrollRef.current = new LocomotiveScroll({
          el: scrollRef.current,
          smooth: true,
          smartphone: { breakpoint: 0, smooth: true },
          tablet: { breakpoint: 0, smooth: true },
        });

        locoScrollRef.current.on("scroll", ScrollTrigger.update);

        ScrollTrigger.scrollerProxy(scrollRef.current, {
          scrollTop(value) {
            return arguments.length
              ? locoScrollRef.current.scrollTo(value, 0, 0)
              : locoScrollRef.current.scroll.instance.scroll.y;
          },
          getBoundingClientRect() {
            return scrollRef.current
              ? scrollRef.current.getBoundingClientRect()
              : {
                  top: 0,
                  left: 0,
                  width: window.innerWidth,
                  height: window.innerHeight,
                };
          },
          pinType:
            scrollRef.current && scrollRef.current.style.transform
              ? "transform"
              : "fixed",
        });

        ScrollTrigger.addEventListener("refresh", () =>
          locoScrollRef.current.update()
        );
        ScrollTrigger.refresh();

        setTimeout(() => {
          /* console.log("Website ready."); */
          setIsReady(true);
        }, 500);

        // Force ScrollTrigger to sync
        setTimeout(() => {
          ScrollTrigger.refresh();
          locoScrollRef.current.update();
          /* console.log("ScrollTrigger & Locomotive Scroll synced."); */
        }, 1000);
      }
    }, 100);
    document.documentElement.classList.add("is-ready");
    return () => {
      clearInterval(checkScrollRef);
    };
  }, [isLoading]);

  useEffect(() => {
    if (locoScrollRef.current) {
      /*  console.log("Updating Locomotive Scroll after data update..."); */
      locoScrollRef.current.update();
    }
  }, [features, shortFilms]);

  return (
    <div
      className="o-scroll"
      ref={scrollRef}
      id="js-scroll"
      data-scroll-container=""
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div
        data-scroll-section=""
        data-scroll-section-id="section0"
        data-scroll-section-inview
        id="topSection"
      >
        <div className="o-container">
          <div
            className="c-header_video_container"
            data-scroll
            data-scroll-speed="6"
          >
            {videoUrl ? (
              <ReactPlayer
                className="dark-video"
                url={videoUrl}
                playing
                muted
                playsinline
                loop
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      type: "video/mp4",
                    },
                  },
                }}
              />
            ) : (
              <p>Loading video...</p>
            )}
          </div>
          <header className="c-header" id="header">
            <div
              className="c-header_heading is-inview"
              data-scroll=""
              data-scroll-speed="-8"
              data-scroll-position="top"
              data-scroll-target="#header"
              data-scroll-repeat=""
            >
              <div className="o-layout">
                <div
                  className="o-layout_item u-1/2 is-inview"
                  data-scroll=""
                  data-scroll-direction="horizontal"
                  data-scroll-speed="-2"
                  data-scroll-position="top"
                  data-scroll-target="#header"
                >
                  <span className="u-label c-header_heading_label">
                    <img
                      src={favicon}
                      alt="Website Logo"
                      style={{ width: "32px", height: "32px" }}
                    />
                  </span>
                </div>
                <div
                  className="o-layout_item u-1/2 is-inview"
                  data-scroll=""
                  data-scroll-direction="horizontal"
                  data-scroll-speed="2"
                  data-scroll-position="top"
                  data-scroll-target="#header"
                >
                  <div className="u-text-right">
                    <span className="u-label c-header_heading_label">
                      <a
                        href="mailto:michael@filmcutter.co.uk"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CONTACT MICHAEL
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <h1 className="c-header_title o-h1 is-inview" data-scroll>
              <span className="c-header_title_line u-white">
                <span
                  data-scroll=""
                  data-scroll-speed="5"
                  data-scroll-position="top"
                  className="is-inview"
                >
                  Michael Cheung
                </span>
              </span>
              <span className="c-header_title_line -version u-cyan">
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="1"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  F
                </span>
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="2"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  I
                </span>
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="1"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  L
                </span>
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="3"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  M
                </span>
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="1"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  &nbsp;&nbsp;
                </span>
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="1"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  EDITOR
                </span>
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="3"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                </span>
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="2"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  VFX
                </span>
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="1"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  &nbsp;&nbsp;
                </span>
                <span
                  className="o-h1 is-inview"
                  data-scroll=""
                  data-scroll-speed="1"
                  data-scroll-position="top"
                  data-scroll-delay="0.05"
                >
                  EDITOR
                </span>
              </span>
            </h1>
          </header>
        </div>
      </div>

      <section
        data-scroll-section
        data-scroll-section-id="section2"
        data-scroll-section-inview
      >
        <div className="o-container">
          <div className="c-summary" data-scroll>
            <div className="o-layout">
              <div className="o-layout_item u-2/5@from-medium">
                <p className="c-summary_text">
                  <a href="https://www.imdb.com/name/nm3525643/">
                    Michael Cheung
                  </a>{" "}
                  is a dedicated Film Editor with over a decade of experience
                  shaping stories through the craft of editing. His career
                  includes work on iconic blockbusters like Rogue One and
                  Gladiator 2 as a VFX Editor, as well as earning an Associate
                  Editor credit on Fall (2022). Michael has also edited a
                  variety of award-winning short films, most recently Deserters
                  (2024), combining technical precision with creative
                  storytelling to deliver impactful and visually stunning work.
                  From cinematic epics to intimate indie projects, his passion
                  lies in bringing compelling narratives to life.
                </p>
              </div>
              <div className="o-layout_item u-2/5@from-medium">
                <ul className="summary_list">
                  <li className="summary_list_item" data-scroll>
                    <a href="#features-list" data-scroll-to>
                      01. FEATURES <span className="summary_list_icon"> ↓</span>
                    </a>
                  </li>
                  <li className="summary_list_item" data-scroll>
                    <a href="#shorts-list" data-scroll-to>
                      02. SHORT FILMS{" "}
                      <span className="summary_list_icon"> ↓</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="c-section"
        data-scroll-section
        data-scroll-section-id="section2"
        data-scroll-section-inview
      >
        <div className="o-container" id="features-list">
          {features.map((feature, index) => {
            return (
              <div
                className={`o-layout -gutter ${
                  index % 2 !== 0 ? "-reverse" : ""
                }`}
                key={feature.id}
              >
                {/* Image First (Left-aligned) */}
                <div className="o-layout_item u-2/5@from-medium">
                  <div
                    className="c-speed-block is-inview"
                    data-scroll=""
                    data-scroll-speed={index % 2 !== 0 ? "2" : "3"}
                  >
                    <div
                      className="o-image_wrapper is-inview"
                      data-scroll=""
                      data-scroll-call="dynamicBackground"
                      data-scroll-repeat=""
                      style={{ backgroundColor: "rgb(212, 209, 201)" }}
                    >
                      <div className="o-image is-inview" data-scroll="">
                        <img
                          className="c-speed-block_image"
                          src={feature.imageUrl}
                          alt={feature.title}
                        />
                        <div className="feature-details">
                          <h3>
                            {feature.title}
                            <span className="contentStudio">
                              {" "}
                              ({feature.studio})
                            </span>
                          </h3>

                          <p className="contentCredit">{feature.credit}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="o-layout_item u-3/5@from-medium">
                  <div
                    className="c-section_info -padding is-inview"
                    data-scroll
                    data-scroll-speed={index % 2 !== 0 ? "-2" : "-1"}
                  >
                    <div
                      className="c-section_infos_inner is-inview"
                      data-scroll=""
                      data-scroll-offset="200"
                    >
                      <h3>
                        {feature.title}
                        <span className="contentStudio">
                          {" "}
                          ({feature.studio})
                        </span>
                      </h3>
                      <div className="c-sections_infos_text u-text">
                        <p className="contentCredit">{feature.credit}</p>
                        <p className="contentDescription">
                          {feature.description}
                        </p>
                        <p className="contentDescription">{feature.awards}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section
        className="c-section"
        data-scroll-section
        data-scroll-section-id="section3"
        data-scroll-section-inview
      >
        <div className="o-container" id="shorts-list">
          {shortFilms.map((film, index) => {
            return (
              <div
                className={`o-layout -gutter ${
                  index % 2 !== 0 ? "-reverse" : ""
                }`}
                key={film.id}
              >
                {/* Image First (Left-aligned) */}
                <div className="o-layout_item u-2/5@from-medium">
                  <div
                    className="c-speed-block is-inview"
                    data-scroll=""
                    data-scroll-speed={index % 2 !== 0 ? "2" : "3"}
                  >
                    <div
                      className="o-image_wrapper is-inview"
                      data-scroll=""
                      data-scroll-call="dynamicBackground"
                      data-scroll-repeat=""
                      style={{ backgroundColor: "rgb(212, 209, 201)" }}
                    >
                      <div className="o-image is-inview" data-scroll="">
                        <img
                          className="c-speed-block_image"
                          src={film.imageUrl}
                          alt={film.title}
                        />
                        <div className="feature-details">
                          <h3>
                            {film.title}{" "}
                            <span className="contentStudio">
                              {" "}
                              ({film.studio})
                            </span>
                          </h3>

                          <p>{film.credit}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="o-layout_item u-3/5@from-medium">
                  <div
                    className="c-section_info -padding is-inview"
                    data-scroll
                    data-scroll-speed={index % 2 !== 0 ? "-2" : "-1"}
                  >
                    <div
                      className="c-section_infos_inner is-inview"
                      data-scroll=""
                      data-scroll-offset="200"
                    >
                      <h3>
                        {film.title}{" "}
                        <span className="contentStudio"> ({film.studio})</span>{" "}
                      </h3>
                      <div className="c-sections_infos_text u-text">
                        <p className="contentCredit">{film.credit}</p>
                        <p className="contentDescription">{film.description}</p>
                        <p className="contentDescription">{film.awards}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <div
        data-scroll-section=""
        data-scroll-section-id="section4"
        data-scroll-section-inview
        id="bottomSection"
      >
        <div className="o-container">
          <div className="c-damn" id="footer">
            <div
              className="c-header_heading is-inview"
              data-scroll=""
              data-scroll-speed="-6"
              data-scroll-position="bottom"
              data-scroll-target="#footer"
              data-scroll-repeat=""
            >
              <div className="o-layout">
                <div
                  className="o-layout_item u-2/5 is-inview"
                  data-scroll=""
                  data-scroll-direction="horizontal"
                  data-scroll-speed="-2"
                  data-scroll-position="bottom"
                  data-scroll-target="#footer"
                >
                  <span className="u-label c-header_heading_label">
                    <img
                      src={favicon}
                      alt="Website Logo"
                      style={{ width: "32px", height: "32px" }}
                    />
                  </span>
                </div>
                <div
                  className="o-layout_item u-1/5 is-inview"
                  data-scroll=""
                  data-scroll-direction="horizontal"
                  data-scroll-speed="-2"
                  data-scroll-position="bottom"
                  data-scroll-target="#footer"
                >
                  <ul className="summary_list">
                    <li className="summary_list_item" data-scroll>
                      <a href="#topSection" data-scroll-to>
                        BACK TO TOP{" "}
                        <span className="summary_list_icon"> ↑</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="o-layout_item u-2/5 is-inview"
                  data-scroll=""
                  data-scroll-direction="horizontal"
                  data-scroll-speed="-2"
                  data-scroll-position="bottom"
                  data-scroll-target="#footer"
                >
                  <div className="u-text-right">
                    <span className="u-label c-header_heading_label">
                      <a
                        href="mailto:michael@filmcutter.co.uk"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CONTACT MICHAEL
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    /*</>
    )
    } 
    </>*/
  );
};
export default App;
